import {MeetingProvider} from "@videosdk.live/react-sdk";
import {AudioControls2,Counter,Biodata, AudioMents } from "./components"
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ArrowLeft2,Message } from "iconsax-react"
import axios from "axios";
import { createMeeting } from "./components";
import { duration } from "@mui/material";
import SingleChat from "../chat/components/SingleChat";
import { useMediaDevice } from "@videosdk.live/react-sdk";
import Rating from "../../Components/rating";

const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiI5MWY1MTkzZC1hODg0LTQzYWMtYWFmNS1iZTM2YjFmNmE2NWIiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTczMjkxODAyNCwiZXhwIjoxNzY0NDU0MDI0fQ.nj8TLX6NncO-bV4zKSfjc8IzDFL2YtzLrVGvTKyN7uI"
const isProd  = (window.location.hostname === "requestmechanic.com"); 

const baseUrl = `${isProd ? "https://requestnowmechanic.com/v1":"https://requestnowmechanic.com/staging/v1"}` 
 

export default function AudioCallz(){
const [authoken,setAuthoken] = useState(null);
const [showRatingModal,setShowRatingModal] = useState(false);
const [showChat,setShowChat] = useState(false);
const [startTimer,setStartTimer] = useState(false);
const [searchParams, setSearchParams] = useSearchParams();
const [meetingId, setMeetingId] = useState(null);
const [call_id, setCall_id] = useState();
const [callDuration, setCallDuration] = useState(null);
const id = null;
const userEmail = localStorage.getItem("userEmail");
const mechanic_id = searchParams.get("mechanic_id");
const token = searchParams.get("token")
const name = searchParams.get("name")
const image = searchParams.get("image")
const model = searchParams.get("model")
const mechanicEmail = searchParams.get("email")
const [notifcationPayload, setnotificationPayload] = useState({ownerId:"",
                                                               ownerName:"",
                                                               meeting_id:'',
                                                               call_token:'',
                                                               owner_fcm:"",
                                                               fcm_token:"",
                                                               is_call:false,
                                                               is_voice:false,
                                                               initiate_id:"",
                                                               owner_image:"",
                                                               email:"",
                                                               duration:"",
                                                               model:""})

function onMeetingLeave(){
  setMeetingId(null);
};

function handleBack(){
    window.history.back();
}

function GenerateMeetingToken(){
  const accessToken = localStorage.getItem('authAccessToken');
      if (accessToken) {
              axios.get(`${baseUrl}/calls/generate-videosdk-token/`,
              {
                  headers:{
                      'Authorization': `Bearer ${accessToken}`,
                  }
              }).then(function(response){
                  console.log("token response",response.data?.token);
                  return response
              }).then((response)=>{
                let data = JSON.stringify(response.data?.token)
                setAuthoken(init=> response.data?.token)
                console.log("token data is ",data);
                console.log("raw response ",response.data?.token);
                return response
              }).catch(function(error){
                  console.log(error);
              })

      }
}

function closeChat(){
  setShowChat(()=>false);
}

function ShowChat(){
  setShowChat(()=>true);
}

useEffect(function(){

  if(authToken != null){
    console.log("in the create meetng effect");
    const meetingId = (id == null) ?  createMeeting(authToken).then(function(response){
      console.log("meeting id gotten");
      setMeetingId(init=>response);
      return response
    }).catch(function(error){
      console.log("meeting id error",error);
    }) : setMeetingId(init=>id);
  }

},[]);

useEffect(function(){
    const accessToken = localStorage.getItem('authAccessToken');
    if(accessToken){
      if (meetingId !== null) {
            axios.post(`${baseUrl}/calls/initiate/`,{
              mechanic_id:mechanic_id,
              room_id:meetingId
            },{
              headers:{
                'Authorization':`Bearer ${accessToken}`,
                'Content-Type':'application/json'
              }
            }).then(function(response){
              console.log("initiate call response",response?.data?.id);
                setCall_id(init=>response?.data?.id);
                setCallDuration(init=>response?.data?.expected_call_duration);
                return response
            }).catch(function(error){
              console.log(error);
            })
      } 
    }
},[meetingId])

useEffect(function(){
const firstname = localStorage.getItem("firstName")
const lastname = localStorage.getItem("lastName")
const ownerToken = localStorage.getItem("deviceToken")
const accessToken = localStorage.getItem('authAccessToken');
    if(accessToken){
        axios.get(`${baseUrl}/user/user/${userEmail}/`,{
          headers:{
            'Authorization':`Bearer ${accessToken}`,
            'Content-Type':'application/json'
          }
        }).then(function(response){
          if(meetingId != null && callDuration != null){
            if(userEmail){
              setnotificationPayload(init=>{return {
              ownerId:response?.data?.data?.id,
              ownerName:`${firstname} ${lastname}`,
              meeting_id:meetingId,
              call_token:authToken,
              owner_fcm:ownerToken,
              fcm_token:token,
              is_call:true,
              is_voice:true,
              initiate_id:call_id,
              owner_image:response?.data?.data?.profile_pic,
              email:userEmail,
              call_duration:callDuration,
              model:model
            }})
              console.log("notification payload created");
            }
          }
        }).catch(function(error){
          console.log(error);
        })
    }

},[callDuration])

useEffect(function(){
  const accessToken = localStorage.getItem('authAccessToken');
  const formattedValue =  JSON.stringify(notifcationPayload)

if(notifcationPayload.ownerId !=""){
  console.log("about to create notification payload");
      axios.post(`${baseUrl}/client/send-message/`,{
        ...notifcationPayload
    },{
        headers:{
            'Authorization':`Bearer ${accessToken}`,
            'Content-Type':'application/json'
        }
    }).catch(function(error){
      console.log("notification endpoint call error",error);
    })
}

},[notifcationPayload])

    return <div className="w-screen h-screen p-4">
                {(authToken !=null && meetingId !=null ) && (<MeetingProvider config={{ meetingId:meetingId, micEnabled: true, webcamEnabled: false, name:name,multiStream:false}} reinitialiseMeetingOnConfigChange={true} joinWithoutUserInteraction={true}
                  token={'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiI5MWY1MTkzZC1hODg0LTQzYWMtYWFmNS1iZTM2YjFmNmE2NWIiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTczMjkxODAyNCwiZXhwIjoxNzY0NDU0MDI0fQ.nj8TLX6NncO-bV4zKSfjc8IzDFL2YtzLrVGvTKyN7uI'}>
                <div className="w-full" onClick={handleBack}><ArrowLeft2/></div>
                <div className="w-full h-[75%] flex flex-col justify-end gap-[4rem] items-center">
                    <div className="flex flex-col gap-[2rem] justify-center  items-center">
                        <Biodata  carmodel={model} image={image} name={name}/>
                        {startTimer ? <Counter/>:<p>Calling....</p>}
                    </div>
                    <AudioControls2  stopTimer={function(){setStartTimer(init=>false)}} showCallRatingModal={()=>setShowRatingModal(init=>true)} name={name} room_id={meetingId} mechanicToken={token} />
                    <div onClick={ShowChat}> <Message/> </div>
                </div>
                <AudioMents name = {name} showCallRatingModal={()=>setShowRatingModal(init=>true)} room_id = {meetingId} call_id={call_id} stopTimer={function(){setStartTimer(init=>false)}} startTimer={function(){setStartTimer(init=>true)}}/>
                </MeetingProvider>)}
                {showChat && <div className="absolute top-0 left-0 z-[10]  ">
                          <SingleChat handleBack={closeChat} carModel={model} mechanicName={name} profile_pic={image} mechanicEmail={mechanicEmail} carOwnerEmail={userEmail}   />
              </div>}
                {showRatingModal && <Rating callId={call_id} closeFunction={()=>{setShowRatingModal(()=>false);window.history.back()}} />}
    </div>
}
