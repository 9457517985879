

import { useEffect, useState } from "react";
import { BellUI } from "./components";
import { useNavigate } from "react-router-dom";

export default function BellNotification({top,right}){
    const [displayBell,setDisplayBell] = useState(0);
    const [refresh,setRefresh] = useState(false);
    const navigate = useNavigate();
    function getNumberOfLocalStorageNotifications(){
        let number_of_notifications_in_localStorage = Number(localStorage.getItem("notification-count"));
        return number_of_notifications_in_localStorage
    }
    useEffect(function(){
        async function getNumberOfCacheStoredNotifications(){
            let number_of_notifications_in_cache = 0;
            await window?.caches?.open("notification-cache").then(async function(cache){
                let cacheKeys = await cache.keys();
                number_of_notifications_in_cache = cacheKeys.length;
                console.log("number_of_notifications_in_cache is", number_of_notifications_in_cache);
                return
            }).catch(error=>console.log(error));
            return Number(number_of_notifications_in_cache);
        }
         function GetNumberOfNotifications(){
            let number_of_notifications_in_localStorage =  getNumberOfLocalStorageNotifications();
            setDisplayBell(()=> number_of_notifications_in_localStorage );
        }
        async function saveCacheNotificationsToLocalStorage(){
            let number_of_notifications = await getNumberOfCacheStoredNotifications()
            console.log("number of notifications in the cache is ",number_of_notifications);
            if( number_of_notifications > 0){
                for (let index = 0; index < number_of_notifications; index++) {
                    console.log("current cache item iteration on is",`/notif-${index}`);
                    await caches.match(`/notif-${index}`).then(function(cached_response){
                        cached_response.json().then(function(response){
                            saveNotificationData(response);
                        })
                    });
                    deleteCacheItem(index);
                }
            }
        }
        async function deleteCacheItem(index){
            window?.caches?.open("notification-cache").then(async function(cache){
                cache.delete(`/notif-${index}`).then(function(response){
                console.log("the status of cache item",`/notif-${index}:`,response);
            }).catch(error=>console.log(error))
        })
        }
        saveCacheNotificationsToLocalStorage();
        deleteExpiredNotification()
        GetNumberOfNotifications();
    },[refresh]);
    function handleUpdateNotificationEvent(event){
        setDisplayBell(()=>true)
        console.log("this is the event received ");
        saveNotificationData(event?.detail);
        refreshNotificationCount();
    }
    function saveNotificationData(data){
        console.log("this is the data received ",data);
        let notification_is_not_empty = notificationIsNotEmpty()
        if(notification_is_not_empty){
            let newValue  = Number(notification_is_not_empty) + 1;
            localStorage.setItem("notification-count",String(newValue));
            saveToLocalStorage(`notif_data_${newValue}`,AppendTimeStamp({...data}));
        }else{
            localStorage.setItem("notification-count","1");
            saveToLocalStorage(`notif_data_1`,AppendTimeStamp({...data}));
        }
    }
    function saveToLocalStorage(storageId,value){
        console.log("this is the value to be saved",value);
        let parsedValue = JSON.stringify(value)
        localStorage.setItem(storageId,parsedValue)
    }
    function notificationIsNotEmpty(){
        const numberOfNotifications = localStorage.getItem("notification-count");

        return numberOfNotifications !== null ? numberOfNotifications : 0;
    }
    function refreshNotificationCount(){
        setRefresh(init=>!init)
    }
    function AppendTimeStamp(data){
        const seven_days_ahead = Number(Date.now()) + Number(86400000);
        const five_seconds_ahead = Number(Date.now()) + Number(5000);
        let timeAppendedData = {...data,expire_on:seven_days_ahead};
        console.log("this is the result of the time appended data",timeAppendedData);
        console.log("this is the time appended data",timeAppendedData.expire_on);
        console.log("this is the time now",Date.now());
        return timeAppendedData;
     }
    function deleteExpiredNotification(){
        const timeNow = Date.now();
        let number_of_notifications_in_localStorage = getNumberOfLocalStorageNotifications();
        for (let index = 1; index <= number_of_notifications_in_localStorage; index++) {
            const currentNotificationItem = JSON.parse(localStorage.getItem(`notif_data_${index}`))
            console.log("the current item is",currentNotificationItem,"with a time difference of",Number(timeNow)-Number(currentNotificationItem?.expire_on));
            if (Number(timeNow) >= Number(currentNotificationItem?.expire_on)) {
                localStorage.removeItem(`notif_data_${index}`);
                number_of_notifications_in_localStorage = number_of_notifications_in_localStorage - 1
                localStorage.setItem("notification-count",number_of_notifications_in_localStorage);
                console.log("item to be removed is ",`notif_data_${index}`);
            }
        }
    }
    useEffect(function(){
        document.addEventListener("update-notification-call",handleUpdateNotificationEvent);
        return function(){
            document.removeEventListener("update-notification-call",handleUpdateNotificationEvent)
        }
    })

    function handleBellClick(){
        navigate("/notifications");
    }

    return <div data-testid="bell-component" style = {{display:displayBell?"block":"hidden",opacity:displayBell?1:0,top,right}} onClick={handleBellClick} className={`absolute self-end right-[${right}] top-[${top}] bg-slate-400 rounded-full p-2`}>
                <BellUI no_of_unread_notifications={displayBell} />
    </div>
}



// {
//     "ownerName": "",
//     "call_token": "eFncv43vR495aAcmcfCO7o:APA91bFL8SBOBo8n-17qQc-8UoKNRKpjms3wXMVS0Q4lZSUMBqkQDTc9swAs7EhCzHYyEYTjhSKmy9PVPBOAd_Wind1xs129kO-Mo1X0UkW3V1NADzx3mw4",
//     "owner_image": "",
//     "ownerId": "bd400c29dbbde3e7ae9aa8c1bbd517f9d883ab4a3ace15ca87046ec5413552a493c143528f9c8da4d84a11663779daf8c5dfda60b2f2b709396542a752803b0e",
//     "is_voice": "False",
//     "email": "dani3lsavag3@gmail.com",
//     "owner_fcm": "None",
//     "is_cancelled_call": "False",
//     "is_update": "True",
//     "is_call": "False",
//     "owner_car_brand": "",
//     "initiate_id": "",
//     "owner_car_model": "",
//     "meeting_id": "",
//     "ownerEmail": "ochuko@requestmechanic.com"
// }



// CustomEvent {isTrusted: false, detail: {…}, type: 'update-notification-call', target: document, currentTarget: document, …}
// isTrusted
// : 
// false
// bubbles
// : 
// false
// cancelBubble
// : 
// false
// cancelable
// : 
// false
// composed
// : 
// false
// currentTarget
// : 
// null
// defaultPrevented
// : 
// false
// detail
// : 
// {from: '99259155360', collapseKey: undefined, messageId: '7db4bf55-d86e-45af-a701-15e794704121', notification: {…}, data: {…}}
// eventPhase
// : 
// 0
// returnValue
// : 
// true
// srcElement
// : 
// document
// target
// : 
// document
// timeStamp
// : 
// 63879.799999952316
// type
// : 
// "update-notification-call"
// [[Prototype]]
// : 
// CustomEvent


// {
//     "from": "99259155360",
//     "messageId": "7db4bf55-d86e-45af-a701-15e794704121",
//     "notification": {
//         "title": "testing",
//         "body": "sending to all"
//     },
//     "data": {
//         "ownerName": "",
//         "call_token": "eFncv43vR495aAcmcfCO7o:APA91bFL8SBOBo8n-17qQc-8UoKNRKpjms3wXMVS0Q4lZSUMBqkQDTc9swAs7EhCzHYyEYTjhSKmy9PVPBOAd_Wind1xs129kO-Mo1X0UkW3V1NADzx3mw4",
//         "owner_image": "",
//         "ownerId": "bd400c29dbbde3e7ae9aa8c1bbd517f9d883ab4a3ace15ca87046ec5413552a493c143528f9c8da4d84a11663779daf8c5dfda60b2f2b709396542a752803b0e",
//         "is_voice": "False",
//         "email": "dani3lsavag3@gmail.com",
//         "owner_fcm": "None",
//         "is_cancelled_call": "False",
//         "is_update": "True",
//         "is_call": "False",
//         "owner_car_brand": "",
//         "initiate_id": "",
//         "owner_car_model": "",
//         "meeting_id": "",
//         "ownerEmail": "ochuko@requestmechanic.com"
//     }
// }