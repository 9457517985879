
import { TextField,Button } from "@mui/material";
import axios from "axios";
import { ArrowLeft2 } from "iconsax-react";
import { useEffect, useState } from "react";
import { PaystackButton } from "react-paystack";
import { useNavigate } from "react-router-dom";

const isProd  = (window.location.hostname === "requestmechanic.com"); 

const baseUrl = `${isProd ? "https://requestnowmechanic.com/v1":"https://requestnowmechanic.com/staging/v1"}` 
 

export default function Topup(){
    const testKey = "pk_test_fe5246f84602ecaa3e268e7fc9697becdd858bea"
    const liveKey = "pk_live_e1cf359d2c1a28bda316bcb2a6385db7a3ce2f9f"
    const [amount,setAmount] = useState();
    const [email,setEmail] = useState(null);
    const navigate = useNavigate();

    useEffect(function(){
        const email = localStorage.getItem("userEmail")
        setEmail(init=>email)
    },[])

    function handleAmountChange(value){
        setAmount(init=>value);
    }
    function handleBack(){
        window.history.back()
    }

    function handleSuccess(e){
        const accessToken = localStorage.getItem('authAccessToken');
        if(accessToken){
            axios.post(`${baseUrl}/wallets/fund-user-wallet/`,{
                amount:amount,
                trans_id:e.reference
            },{
                headers:{
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type':'application/json'
                }
            }).then(function(result){
                navigate("/wallet");
            }).catch(function(error){
                console.log(error);
            })
        }
    }
    function handleError(){

    }

    return <div className="w-screen p-4">
                <div className="mb-4 flex text-xl items-center"><ArrowLeft2 onClick={handleBack} /> <p className="font-bold">Top Up</p></div>
                <div className=" w-full">
                    <p className="py-2 ">Amount</p>
                    <TextField placeholder="500.00" onChange={(e)=>handleAmountChange(e.target.value)} className="w-full" style={{fontFamily:"SharpSans"}} type="number" />
                    <PaystackButton className={"w-full bg-green-500 text-white p-2 mt-4 rounded-md"}  onSuccess={(e)=>handleSuccess(e)}  amount={Number(amount) * 100} email={email} publicKey={isProd?liveKey:testKey} text="Continue"  />
                </div>
    </div>
}
