import { useState, useEffect, useRef } from "react";
import {
  User,
  ArrowRight2,
  BrifecaseTimer,
  MessageQuestion,
  Messages1,
  Verify,
  ArchiveBook,
  Logout,
  ProfileDelete,
  Edit,
} from "iconsax-react";
import small from "../Images/profile image backdrop/small.png";
import medium from "../Images/profile image backdrop/medium.png";
import large from "../Images/profile image backdrop/large.png";
import xlarge from "../Images/profile image backdrop/xlarge.png";
import placeholder from "../Images/1 1.png";
import { Card } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import { NavBar } from "./NavBar";
import { DeleteModal } from "./DeleteModal";
import CircularProgress from '@mui/material/CircularProgress';
import imageCompression from "browser-image-compression";


const isProd  = (window.location.hostname === "requestmechanic.com"); 

const baseUrl = `${isProd ? "https://requestnowmechanic.com/v1":"https://requestnowmechanic.com/staging/v1"}` 
 

export default function MechProfile() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const [profileName, setProfileName] = useState("");
  const [isActive,setIsActive] = useState(false);
  const [isAvailable, setisAvailable] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [uploading, setUploading] = useState(false)
  const fileInputRef = useRef(null);

  function changeProfilePic() {
    if (fileInputRef != null) {
      fileInputRef.current?.click();
    }
  }

  function handleFilechange(file) {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
  }
    setProfilePic((init) => URL.createObjectURL(file));
    const accessToken = localStorage.getItem("authAccessToken");
    if (accessToken) {
      try{
        setUploading(init => true)
        imageCompression(file, options).then(function(compressedFile){
            console.log(`compressed image has a size of ${compressedFile.size / 1024 / 1024} MB`);
            console.log(`this is the compressed image ${compressedFile} `);
            axios.put(`${baseUrl}/user/profile/update-profile-picture/`,
            {
                profile_pic: new File([compressedFile],compressedFile.name,{
                  type:compressedFile.type
                })
            },{
                headers:{
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'multipart/form-data',
                }
            }).then(function(response){
                console.log(response);
                console.log("image uploaded");
                setUploading(init => false)
                return response
            }).catch(function(error){
                console.log(error);
                setUploading(init => false)
            })
        })
    }catch{
        console.log("an error occured while fetching profile data")
    }
    }
  }

  useEffect(function () {
    const accessToken = localStorage.getItem("authAccessToken");
    const userEmail = localStorage.getItem("userEmail");
    if (accessToken) {
      try {
        axios
          .get(
            `${baseUrl}/user/user/${userEmail}/`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          )
          .then(function (response) {
            setProfileName(
              (init) =>
                `${response.data.data.first_name} ${response.data.data.last_name}`
            );
            setProfilePic((init) => response.data.data.profile_pic);
            console.log("mechanic profile page query response", response);
            return response;
          })
          .catch(function (error) {
            console.log(error);
          });
      } catch {
        console.log("an error occured while fetching profile data");
      }
    }

    if (accessToken) {
      try {
        axios
          .get(
            `${baseUrl}/user/registration/step3/mechanic/`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          )
          .then(function (response) {
            setisAvailable((init) => response.data.data.update_is_active);

            console.log(
              "mechanic profile availability page query response",
              response
            );
            return response;
          })
          .catch(function (error) {
            console.log(error);
          });
      } catch {
        console.log("an error occured while fetching profile data");
      }
    }
  }, []);

  useEffect (()=> {
    const accessToken = localStorage.getItem("authAccessToken");
    const tokenExpiry = localStorage.getItem("tokenExpiry");
    const now = new Date().getTime();

    //deletes the access token stored in local storage after 7 days(if now(the present time) > the time from login)
    if (accessToken) {
        if (now > tokenExpiry) {
          localStorage.removeItem("authAccessToken");
          localStorage.removeItem("tokenExpiry");
          navigate("/login-user"); //navigates to login page after deleting
        }
    }

}, [])

  useEffect(()=>{
    const accessToken = localStorage.getItem("authAccessToken");

    if (accessToken) {
        axios.get(
            `${baseUrl}/client/get-availability/`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          ).then(response=>{
            setIsActive(init => response.data?.data?.is_active)
          })
        }
      
  },[])

  const logout = async () => {
    const accessToken = localStorage.getItem("authAccessToken");
    const refreshToken = localStorage.getItem("authRefreshToken");
    setLoading(true);
    try {
      const response = await axios.post(
        `${baseUrl}/user/logout/`,
        { refresh: refreshToken },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      if (response.status === 200) {
        console.log("Logout successful");
        localStorage.clear();
        localStorage.removeItem("authAccessToken");
        localStorage.removeItem("authRefreshToken");
        navigate("/login-user");
      } else {
        console.error("Logout failed with status: ", response.status);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Profile>
        <div className="w-screen">
          <div className="flex flex-col justify-center items-center">
            <picture>
              <source srcSet={small} media="(min-width: 1240px)" />
              <source srcSet={medium} media="(min-width: 960px)" />
              <source srcSet={large} media="(min-width: 726px)" />
              <source srcSet={xlarge} media="(min-width: 456px)" />
              <img src={medium} className="w-full h-[15vh]" />
            </picture>
            {profilePic ? (
              <img
                src={profilePic}
                alt="profile pic"
                className="w-[6rem] h-[6rem] rounded-full relative bottom-[3.5rem]"
              />
            ) : (
              <div className="bg-slate-400 rounded-full p-6 relative bottom-[2.5rem]">
                <User variant="TwoTone" className="w-[4rem] h-[4rem]" />
              </div>
            )}
            <input
              onChange={(e) => handleFilechange(e.target?.files[0])}
              type="file"
              ref={fileInputRef}
              className="hidden"
            />
            <div className=" relative bottom-[5rem] left-[2.5rem] w-fit h-fit p-2 rounded-full bg-teal-300">
              <Edit
                onClick={changeProfilePic}
                className=" w-[2rem] h-[2rem] "
              />
            </div>
            <p className="relative bottom-[2rem] font-bold text-2xl flex gap-2 items-center">
              {profileName}
              <Verify variant="Bold" className="text-yellow-400" />
            </p>
            <span
              className={`${
                isActive ? "bg-teal-400 " : "bg-red-500"
              } bg-slate-400 relative bottom-[1.6rem] px-2 py-1 rounded-2xl text-lg text-teal-800"`}
            >
              {isActive ? "Active" : "Away"}
            </span>
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex flex-col px-4 mt-50 gap-2">
              <p className="font-bold mb-3">My Account</p>
              <Sub
                title={"Personal Information"}
                Icon1={User}
                link={"/personal-info"}
              />
              <Sub
                title={" Your Availability"}
                Icon1={BrifecaseTimer}
                link={"/mechanic-availability"}
              />
            </div>
            <div className="flex flex-col px-4 gap-2">
              <p className="font-bold ">Support and Legal</p>
              <Sub title={"FAQ'S"} Icon1={MessageQuestion} />
              <Sub title={"Get Help"} Icon1={Messages1} link={"/user-help"} />
              <Sub title={"Legal"} Icon1={ArchiveBook} link={"/legal"} />
              <div onClick={() => setShowDelete(!showDelete)}>
                <DeleteAccount title={"Delete Account"} Icon1={ProfileDelete} />
              </div>
              <div onClick={() => logout()}>
                <LogOut
                  title={loading ? "Logging out..." : "Logout"}
                  Icon1={Logout}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bottom">
          <NavBar />
        </div>
      </Profile>
      <DeleteModal showModal={showDelete} setShowModal={setShowDelete}/>
      {uploading && <BlurLoader/>}
    </>
  );
}

function BlurLoader(){
  return <div style={{backgroundColor:"rgba(0,0,0,0.8)"}} className="absolute w-screen h-screen top-0 left-0  flex flex-col justify-center items-center">
            <CircularProgress variant="indeterminate" color="success" />
            <p className="text-white">Image Uploading please Wait</p>
  </div>
}

function Sub({ title, Icon1, link }) {
  return (
    <Link to={link}>
      <Card sx={{ padding: "1.5rem 1rem" }}>
        <div className="flex justify-between">
          <p className="flex gap-2 justify-between">
            {Icon1 && <Icon1 />}
            {title}
          </p>
          <ArrowRight2 />
        </div>
      </Card>
    </Link>
  );
}

function LogOut({ title, Icon1 }) {
  return (
    <Card sx={{ padding: "1.5rem 1rem" }}>
      <div className="flex justify-between cursor-pointer">
        <p className="flex gap-2 justify-between">
          {Icon1 && <Icon1 />}
          {title}
        </p>
        <ArrowRight2 />
      </div>
    </Card>
  );
}
function DeleteAccount({ title, Icon1 }) {
  return (
    <Card sx={{ padding: "1.5rem 1rem" }}>
      <div className="flex justify-between cursor-pointer">
        <p className="flex gap-2 justify-between">
          {Icon1 && <Icon1 />}
          {title}
        </p>
        <ArrowRight2 />
      </div>
    </Card>
  );
}

const Profile = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 6rem;
  position: relative;

  .bottom {
    margin-top: 2rem;
    height: 5rem;
    padding: 20px 0;
    position: fixed;
    bottom: 0px;
    right: 0px;
    left: 0px;
    z-index: 100;
    background-color: #ffffff;
    border-top: 1px solid #dedede;
  }
`;
